.header-wrap {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    background-color: rgba(255,255,255, 0.8);
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.25);
}
.header {
    padding: 1em 0.5em;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    @include mq(50em) {
      align-items: center;
      max-width: $max-width;
    }
    @include mq($max-width) {
        padding: 1em 0;
    }
}
.header-logo_small {
    transition: all 1s ease-out;
    .header-nav {
      margin-top: 0.5em;
      transition: all 1s ease;
    }
    .js-header-nav_item-menu {
      top: 0.6em;
      transition: 0.65s ease-in;
    }
    .header-logo_wrap img {
      width: 60%;
      @include mq(50em) {
        width: 40%;
      }
    }
}
.header-logo_wrap {
    margin: 0;
    flex-basis: 80%;
    width: 80%;
    padding-right: 1em;
    @include mq(50em) {
      flex-basis: 60%;
      width: 60%;
    }
    .header_logo-link {
        margin: 0;
        img {
            transition: all 1s ease-in;
        }
    }
}
.header-nav {
    transition: 1s;
    padding-left: 0;
    margin: 0 auto;
    margin-top: 1.5em;
    text-align: right;
    @include mq(50em) {
      padding-left: 1em;
      margin-top: 0;
      display: table;
    }
}
.header-nav-wrap {
    width: 100%;
    padding: 0.75em 1em;
     @include mq($max-width) {
        padding: 0.75em 0;
    }
}
.header-nav_item {
    display: none;
    padding-bottom: 0.5em;
    @include mq(50em) {
        display: inline-block;
        font-weight: 400;
        display: inline;
        margin-right: 2em;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

.header-nav_item a {
    color: $color-blue;
    text-decoration: none;
}

.header-nav_item-menu {
    display: inline;
    @include mq(50em) {
        display: none;
  }
}

.header-mobile_open {
  margin-bottom: 0;
  width: 100%;
  .header-nav_item {
    display: block;
    width: 100%;
    &.header-nav_item-menu {
      padding-left: 1em;
      display: inline-block;
      width: 2.5em;
    }
  }
}

.js-header-nav_item-menu {
  position: absolute;
  right: 1em;
  top: 1.7em;
  transition: 0.35s ease-in;
}

.header-nav_item-home {
  margin-top: 2em;
}