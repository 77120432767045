.hp-services_wrap {
    border-top: 20px solid $color-blue;
    margin-bottom: -11em;
    // background-color: #222;
    // .content-wrap {
    //     padding: 2.5em 0;
    // }
}
.hp-service_inner {
    @include mq(tablets) {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        margin-top: -8em;
    }

}

.hp-service {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 16em;
    margin: 0.5em 0;
    color: $color-white;
    text-decoration: none;
    border: 1px solid $color-grey-light;
    @include mq(wide_tablets) {
        width: 30.5%;
        flex-basis: 30.5%;
    }

    h2 {
        font-size: 1.5em;
        margin-bottom: 0;
        padding: 0.25em 0;
        font-weight: 200;
        color: $color-grey-light;
        @include mq(tablets) {
            font-size: 2em
        }
    }

    &:hover {
        cursor: pointer;
        border: 1px solid darken($color-grey-light, 25%);
        h2 {

            border-bottom: 2px solid $color-grey-light;
        }
    }
}


.two-column_wrap {
    background: darken($color-blue, 25%) url(/images/hp-about_img.jpg) no-repeat;
    background-size: cover;
    background-position: left;
    padding: 14em 0 4em;
    .content-wrap {
        padding: 1em 0 1em 0;
    }
}

.two-column_border {
    background-color: rgba(0,37,71,0.7);
    max-width: 100%;
    padding: 2em;
}

.two-column_content {
    border: 2px solid #000;
    padding: 2em 2em 2em 2em;
    color: $color-grey-light;
    @include mq(tablets) {
        padding: 3em 4em 3em 4em;
    }
}

.two-column_header {
    text-align: center;
    font-weight: 200;
    color: $color-grey-light;
}

.two-column_paragraph {
    p {
        @include mq(tablets) {
            column-count: 2;
            column-gap: 5em;
        }
    }
}

.hp_bottom-wrap {
    border-bottom: 1px solid rgba($color-blue, 0.5);
    @include mq(tablets) {
        display: flex;
        flex-flow: row wrap;
    }
}

.hp_bottom-left {
    color: $color_blue;
    flex-basis: 50%;
    padding-top: 2em;
    .hp_bottom-paragraph {
        font-size: 1.15em;
    }
}

.hp_bottom-right {
    margin-top: 0;
    flex-basis: 50%;
    @include mq(tablets) {
        width: 50%;
    }
}