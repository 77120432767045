.service-img_01 {
    background: $color-black url(/images/hp-service_01.jpg);
}

.service-img_02{
    background: $color-black url(/images/hp-service_02.jpg);

}

.service-img_03 {
    background: $color-black url(/images/hp-service_03.jpg);
    @include mq(wide_tablets) {
        margin-right: 0;
    }
}

.service-img {
    color: $color-grey-light;
    background-size: cover;
    background-position: center;
    &:hover {
        text-decoration: none;
    }
}

.service_img-heading {
    padding: 1em;
    margin-bottom: 0;
    color: $color-grey-light;
}

.service-links {
    width: 95%;
    margin: 0 auto;
    @include mq(tablets) {
    }
    div {
        margin-bottom: 0.35em;
    }
}

.service_links-img {
    width: 100%;
    margin-bottom: 0.5em;
    background-size: cover;
    background-position: center;
}

.service_links-info {
    margin-top: 0.5em;
    padding: 2em;
    border: 1px solid $color-blue;
    .service_links-heading {
        color: $color-blue;
        font-weight: 300;
        text-align: left;
    }
    hr {
        border: 1px solid $color-blue;
    }
    color: $color-black;
}

.service_guarantee-heading {
    margin-bottom: 0.5em;
    color: $color-grey-light;
}