.footer {
    max-width: 100%;
    color: $color-grey-light;

}

.footer-heading {
    margin-bottom: 0;
    padding-bottom: 0.5em;
    font-weight: 700;
    color: $color-grey-light;
    border-bottom: 1px solid $color-grey-light;
}

.footer-info {
    padding: 5em 0 6em;
    background: $color-blue url(/images/footer.jpg) no-repeat;
    background-size: cover;
    background-position: center;
}

.footer-info_wrap {
    padding: 0;
    line-height: 2;
    ul {
        list-style: none;
        padding-left: 0;
    }
    @include mq(wide_handhelds) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
    }
}
.footer-info_column {
    padding-bottom: 0;
    width: 80%;
    margin-left: 1em;
    hr {
        max-width: 50%;
        margin: 0;
        @include mq(tablets) {
            max-width: inherit;
            margin: auto;
        }
    }
    @include mq(tablets) {
        margin-bottom: 2em;
    }
}
.footer_logo {
    line-height: 1.5;
    font-size: 0.85em;
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(tablets) {
        width: 30%;
    }
}
.footer_nav {
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(tablets) {
        width: 15%;
    }
}
.footer_info {
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(tablets) {
        width: 20%;
    }
}
.footer_projects {
    @include mq(wide_handhelds) {
        width: 40%;
    }
    @include mq(tablets) {
        width: 20%;
    }
}
.footer_icon {
    max-width: 10%;
    padding-right: 0.5em;
}
a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    &:hover {
        text-decoration: underline;
    }
}
.copyright {
    text-align: center;
    padding: 1em 0 1em 0;
    background: rgb(255, 255, 255);
    color: $color-black;
    a { color: $color-blue; }
}
.footer_info li, .footer_nav li{
    font-style: italic;
    font-size: 0.8em;
}