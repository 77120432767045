.gallery-content_wrap {
    padding: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 2.5em;
    @include mq(tablets) {
        flex-flow: row nowrap;
    }
}

.gallery-wrap {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding: 0;
    li {
        margin-right: 1em;
        &:nth-of-type(4n) {
            margin-right: 0;
        }
    }

}

// .gallery-wrap {
//  width: 95%;
//  margin: 0 auto 1.10em;

//  @include mq(tablets) {
//      width: 70%;
//      margin: 0 auto 2.5em;
//  }
//  p {
//      color: #000;
//      margin: 0 0 2em 0;
//      font-size: 1.1em;
//  }
// }

.gallery-li {

}

.gallery_4-small {
    display: flex;
    flex-flow: row wrap;
    div {
        flex-grow: 1;
        width: 10em;
        margin: 0 0.5em 0 0;

        img {
            width: 100%;
        }
    }
    @include mq(tablets) {
        flex-flow: row nowrap;
    }
}
// One big cube image, 1 medium sized image, 2 small images.
.gallery_1-big_3-small {
    display: flex;
    flex-flow: row wrap;
    @include mq(tablets) {
        flex-flow: row nowrap;
    }
    div {
        div {
            margin: 0 0.5em 0 0;
            flex-grow: 1;
        }
        @include mq(tablets) {
            width: 49%;
        }
        :first-child {
            width: 100%;
            margin-right: 0;
        }
        :nth-child(3) {
            margin-right: 0;
        }
    }
    .img_big {
        margin: 0;
        width: 100%;
        @include mq(tablets) {
            margin: 0 0.5em 0.5em 0;
            height: 23em;
            width: 23em;
        }
    }
}

.gallery_backwards {
    display: flex;
    flex-flow: row wrap;
    @include mq(tablets) {
        flex-flow: row nowrap
    }
    div {
        div {
            margin: 0 0.5em 0 0;
            flex-grow: 1;
        }
        @include mq(tablets) {
            width: 49%;
        }
        :first-child {
            width: 100%;
            margin-right: 0;
        }
    }
    :nth-child(3) {
        margin-right: 0;
    }
    .img_big {
        margin: 0;
        height: 23.4em;
        width: 100%;
        @include mq(tablets) {
            margin: 0 0 0 0.5em;
            width: 23em;
        }
    }
}

.three_images {
    display: flex;
    flex-flow: row wrap;
    div {
        height: auto;
        width: 10em;
    }
    @include mq(tablets) {
        div {
            height: 10em;
        }
    }
}


.gal-img, .pop_up-close, .pop_up-next {
    cursor: pointer;
}

// Pop up on gallery image click.
.pop_up {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(21,67,149,0.8);
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.25s
}
@keyframes animatetop {
    from {
        // top: -100px;
        opacity: 0.5;
    }
    to {
        // top: 0;
        opacity: 1;
    }
}


.pop_up-content {
    width: 100%;
    height: 100%;
    z-index: 100001;
}

.img-modal {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 95%;
    text-align: center;
    @include mq(tablets) {
    }
    img {
        background-color: #000;
        padding: 0.25em;
        max-width: 70%;
        max-height: 80vh;
        margin: 10% auto 0;
        text-align: center;
    }
}

.display {
    display: block;
}

.pop_up-next, .pop_up-prev {
    position: absolute;
    top: 40%;
    font-size: 3em;
    z-index: 100002;
}

.pop_up-next {
    right: 10%;
}

.pop_up-prev {
    left: 10%;
}
