.img_bar-wrap-hp {
    background: $color-black url(/images/closed_doors.jpg) no-repeat;
}

.img_bar-wrap-about {
   background: $color-black url(/images/learn-more.jpg) no-repeat;
}

.img_bar-wrap {
    background-size: cover;
    background-position: center;
    color: $color-grey-light;
    h1, h2, h3, h4, h5, h6 {
        color: $color-grey-light;
    }
}

.img_bar-inner {
    text-align: center;
    padding: 10em 0;
}

.img_bar-heading {
    font-size: 2em;
    max-width: 70%;
    margin: 0 auto;
    margin-bottom: 2em;
    font-weight: 200;
}

.button-img_bar_wrap {
    font-size: 1.5em;
    margin: 0 auto;
    padding: 0.5em 0;
    border: 2px solid $color-white;
    border-radius: 25px;
    max-width: 50%;
    transition: all ease 0.8s;

    @include mq(tablets) {
        max-width: 25%;
    }

    &:hover {
        background-color: #31302B;
    }
    a:hover {
        text-decoration: none;
    }
}

.button-img_bar {
    margin: 0;
    padding: 0.5em;
    font-weight: 200;
}

.button-img_bar_wrap:hover {

}