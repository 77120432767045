button, .button {
	border: 3.5px solid $color-blue;
}

.contact-button {

	border-radius: 10px;
	background-color: $color-blue;
	color: white;
	padding: 0.2em 0.5em 0.2em 0.5em;
	margin-left: 0.5em;
}
.testimonial-button {
	display: block;
	padding: 0.65em 6em 0.65em 6em;
	margin: auto;
	margin-top: 3em;
	border: 3.5px solid #123d8a;
	border-radius: 20px;
	background-color: $color-blue;
	color: $color-white;
}