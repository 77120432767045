.testimonials-wrapper {
    padding: 4em 0;
    color: #000;
}

.testimonials-header {

    margin-bottom: 2em;
    text-align: center;
    :first-child {
        margin: 2em 0 0;
        color: $color-black;
        font-weight: 300;
    }
    :nth-child(2) {
        font-size: 2.5em;
        margin: 0;
        color: $color-blue;
        font-weight: 300;

    }
}
.testimonials-posts {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 55em;
    margin: 0 auto;

    ul {
        list-style: none;
    }

    :nth-child(even) {
        .testimonial-inner  {
            justify-content: space-between;
            margin-top: 2em;
        }
        .testimonial-img {
            margin: 0 0 0 2em;
            order: 2;
        }
    }
    a {
        color: rgba($color-black, 0.6);
        font-style: italic;
        font-weight: 700;
    }
}

.testimonial-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    p {
        font-size: 1em;
        min-height: 5.2em;
        padding: 1em;
        background: $color-white url('/images/quote.png') no-repeat;

        @include mq(tablets) {
            font-size: 1.35em;
        }
    }
}
.testimonial-img {
    display: flex;
    flex: 1 0 auto;
    max-width: 7em;
    height: 7em;
    margin-right: 2em;
    @include mq(tablets) {
        max-width: 10em;
        height: 10em;
    }
    img {
        border-radius: 50%;
        width: 100%;
        justify-content: space-between;
    }
}
.testimonials-page {
    padding-top: 0;
}