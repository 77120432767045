.about-content_text {
	font-size: 1.2em;
	max-width: 85%;
	margin: 0 auto;
	@include mq(tablets) {
		max-width: 60%;
	}
}

.about-content_images {
	display: flex;
	flex-flow: column nowrap;
	height: auto;
	margin: 2em 0;
	@include mq(tablets) {
		flex-flow: row nowrap;
		height: 18em;
	}
	* {
		min-width: 25%;
		margin: 0 0 0.2em 0;
		height: 10em;
		@include mq(tablets) {
			height: auto;
			margin: 0 0.2em 0 0;
		}
	}
}
.about_images01 {
	background: url('/images/about-image01.jpg') no-repeat;
	background-position: right;
}
.about_images02 {
	background: url('/images/about-image02.jpg') no-repeat;
	background-position: center;
}
.about_images03 {
	background: url('/images/about-image03.jpg') no-repeat;
	background-position: center;
}
.about_images04 {
	background: url('/images/about-image04.jpg') no-repeat;
	background-position: center;
}
.service-wrap {
	display: flex;
	flex-flow: column nowrap;
	@include mq(tablets) {
		flex-flow: row nowrap;
	}
}
.service_text {
	color: black;
	padding: 0.5em;
	@include mq(tablets) {
		padding-right: 4em;
	}
}


.service_text-paragraph {
	font-size: 1.1em;
}

.about-learn_more.learn_more-inner {
	padding: 1.5em;
	@include mq(tablets) {
		padding: 6em;
	}
	.learn_more-header {
		@include mq(tablets) {
			margin: 0 0 1em 0;
			max-width: 100%;
		}
	}
}