.content-wrap {
    @include clearfix;
    max-width: $max-width;
   	width: 100%;
    margin: 0 auto;
    padding: 1em;
}

.-text-grey {
	color: $color-grey-light;
}