.herobar {
    color: $color-grey-light;
    padding: 5em 0 3em;
    h1, h2, h3, h4, h5, h6 {
        color: $color-grey-light;
    }
}

.herobar-hp {
    display: flex;
    align-items: center;
    padding: 16em 0 15em;
    background: $color-blue url(/images/mountain.jpg) no-repeat;
    .herobar-content {
        margin: 0;
        text-align: left;
    }
    .herobar-text {
        margin: 0;
    }
}

.herobar-about {
    background: $color-black url('/images/herobar-about.jpg') no-repeat;
}

.herobar-contact {
    background: $color-black url('/images/herobar-contact.jpg') no-repeat;
}

.herobar-residential {
    background: $color-black url('/images/herobar-gallery_residential.jpg') no-repeat;
}

.herobar-testimonials {
    background: $color-black url('/images/herobar-testimonials.jpg') no-repeat;
}

.herobar {
    background-size: cover;
    background-position: center;
}

.herobar-content {
    margin: 0 auto;
    text-align: center;
}

.herobar-text {
    font-size: 1.5em;
    font-weight: 300;
    max-width: 30em;
    margin: 0 auto;
}

.herobar-heading, h1.herobar-heading:first-of-type {
    font-size: 3em;
    margin-top: 0.5em;
    font-weight: 400;
}

.herobar-residential-header {
    padding: 7em 0 5em 0;
    text-align: center;
    @include mq(tablets) {
        padding: 10em 0 7em 0;
    }
}