// mixins + normalize + common
@import "common/vars", "public/vars", "common/mixins", "public/mixins", "common/media", "common/common";

// global styles on the most common elements
html {
    font: 100%/1 'Catamaran', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.5;
    color: $text-color;
}

// load common styles
@import "common/forms", "common/pagination", "common/alerts";

// public only styles
@import "public/content",
    "public/buttons",
    "public/header",
    "public/footer",
    "public/headings",
    "public/testimonials",
    "public/herobars",
    "public/img_bar",
    "public/service_links",
    "public/gallery";

// page specific
@import "public/p_index", "public/p_about", "public/p_contact", "public/p_residential";