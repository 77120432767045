.contact-page {
	margin-bottom: 2em;
	padding: 3em 0 3em 0;
	@include mq(tablets) {
		margin-top: 2em;
	}
}
.contact-info-wrap {
	display: flex;
	flex-flow: row wrap;
	justify-content: none;
	align-items: center;
	@include mq(tablets) {
		justify-content: space-around;
	}
	div {
		color: #000;
	}
}
.contact-form {
	order: 2;
	width: 95%;
	padding: 2em;
	margin: 0 auto;
	border: 1px solid $color-blue-transparent;
	@include mq(tablets) {
		order: 1;
		width: 40%;
		margin: 0;
	}
}
.form-contact-input {
	padding: 0.5em;
	textarea, input {
		border: 1px solid #000;
		padding: 0.25em;
		width: 100%;
	}
}
.contact-info-text {
	order: 1;
	align-self: flex-start;
	padding: 1em;
	width: 95%;
	margin: 0 auto;
	@include mq(tablets) {
		padding: 0;
		order: 2;
		width: auto;
		margin: 0;
	}
	hr {
	    border: 1px solid $color-blue;
	}
}
.contact-info_header {
	text-align: center;
}
.banana {
	display: none;
}